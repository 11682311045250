@import '~bootstrap/scss/bootstrap';
@import '~react-toastify/scss/main.scss';
@import '~leaflet/dist/leaflet.css';


@import url(https://fonts.googleapis.com/css?family=Roboto:400,500);


html, body, #app {
  width: 100%;
  height: 100%;
  font-family: Roboto, 'Helvetica Neue', Arial;
  font-weight: 400;
  font-size: 14px;
  @media print {
    height: inherit;
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes progress {
  0% {
    transform: scaleX(1) translateX(-100%);
  }
  100% {
    transform: scaleX(1) translateX(100%);
  }
}

.cursor-pointer {
  cursor: pointer;
}

